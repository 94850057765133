import definitions from "./EditFormFields"

const {
    formField: {
        userName,
        callSign,
        mode,
        sipNumber
    },
  } = definitions;

  
export default {
    [userName.name]: "",
    [callSign.name]: "",
    [mode.name]: 1,
    [sipNumber.name]:"",
}