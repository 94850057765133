import { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import dataTableData from "./MessageDataTables";
import MDBox from "components/MDBox";
import MessageService from "./MessageService";
import Header from "./Header";
import Notification from "../Module/notifications/Notification";
import LoadingSpinner from "components/Helper/LoadingSpinner";
// import { Table } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";

function Message() {
  const [Message, setMessage] = useState([]);
  const [isFetching, setFetching] = useState(false);
  // const [tableData, setTableData] = useState(dataTableData)
  // const [selectedRows, setSelectedRows] = useState([])
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const fetchData = (data) => {
    setMessage(data);
  }

  async function getAllmsg() {
    MessageService.GetList().then(
      (response) => {
        setMessage(response.data.data);
        setFetching(false);
      },
      (error) => {
        setFetching(false);
        console.log(error.response.data);
      }
    );
  }
  useEffect(() => {
    MessageService.refreshList = getAllmsg;
    getAllmsg();
  }, []);


  if (isFetching) {
    return <LoadingSpinner />
  }
//DeleteSelected

// const handleBulkDelete = () => {
//   const updatedData = tableData.filter(row => !selectedRows.includes(row))
//   setTableData(updatedData)
// }


  return (
    <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <Header updateList={(data) => fetchData(data)}
      />
      <Card>
        <MDBox m={1}>
          <MDButton color="info">Delete Selected</MDButton>
        </MDBox>
        <DataTable
          table={{
            columns: dataTableData.columns,
            rows: Message,
           
          }}
          canSearch
        />
     
      </Card>
      <Notification notify={notify} setNotify={setNotify} />
      <Footer />
    </DashboardLayout>
  );
}
export default Message;
