import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "./UserInputControls";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useNavigate } from "react-router-dom";
import UserService from "./UserService";
import { Stack } from "@mui/material";


function NewUser({ formData }) {
  const navigate = useNavigate();
  const { formField, values, errors, touched,isEdit , handleChange } = formData;
  const { userName, callSign, mode, password, confirmPassword , sipNumber } = formField;
  const {
    userName: userNameV,
    mode : modeV,
    callSign: callSignV,
    password: passwordV,
    confirmPassword: confirmPasswordV,
    sipNumber: sipNumberV
  } = values;

  const submitActionName = isEdit ? "Update" : "Add";
  
  const Back = () =>{
    navigate(UserService.listPath);
  }
  
  return (
    <MDBox>
      <MDBox m={3}>
        <FormField
          name={userName.name}
          variant="outlined"
          fullWidth
          id={userName.name}
          label={userName.label}
          type={userName.type}
          placeholder={userName.placeholder}
          error={errors.userName && touched.userName}
          success={!errors.userName}
          value={userNameV}
        />
      </MDBox>
      <MDBox m={3}>
        <FormField
          name={callSign.name}
          variant="outlined"
          fullWidth
          id={callSign.name}
          label={callSign.label}
          type={callSign.type}
          placeholder={callSign.placeholder}
          value={callSignV}
          error={errors.callSign && touched.callSign}
        />
      </MDBox>
      <MDBox m={3}>
        <MDBox>
          <InputLabel >Role</InputLabel>
          <Select
            m={3}
            value={modeV}
            onChange={handleChange}
            label="Role"
            fullWidth
            variant="standard"
            name={mode.name}
            id={mode.name}
            type={mode.type}
            placeholder={mode.placeholder}
          >
            <MenuItem value={1}>Operator</MenuItem>
            <MenuItem value={2}>Handler</MenuItem>
          </Select>
        </MDBox>
      </MDBox>
      <MDBox m={3}>
        <FormField
          name={sipNumber.name}
          variant="outlined"
          fullWidth
          id={sipNumber.name}
          label={sipNumber.label}
          type={sipNumber.type}
          placeholder={sipNumber.placeholder}
          value={sipNumberV}
        />
      </MDBox> 
      <MDBox m={3}>
        <FormField
          name={password.name}
          variant="outlined"
          fullWidth
          id={password.name}
          label={password.label}
          type={password.type}
          placeholder={password.placeholder}
          error={errors.password && touched.password}
          success={!errors.password}
          value={passwordV}
        />
      </MDBox>
      <MDBox m={3}>
        <FormField
          name={confirmPassword.name}
          variant="outlined"
          fullWidth
          id={confirmPassword.name}
          label={confirmPassword.label}
          type={confirmPassword.type}
          placeholder={confirmPassword.placeholder}
          error={errors.confirmPassword && touched.confirmPassword}
          success={!errors.confirmPassword}
          value={confirmPasswordV}
        />
      </MDBox>
      <Stack spacing={2} p={2} direction="row">
        <MDButton type="submit" variant="contained" color="info"  fullWidth onClick={Back}>
          Back
        </MDButton>
        <MDBox />
        <MDButton type="submit" variant="contained"  fullWidth color="info">
          {submitActionName}
        </MDButton>
      </Stack>
    </MDBox>
   
  );
}

export default NewUser;