import * as Yup from "yup";
import formFields from "./ResetFormFields";

const {
  formField: { oldName, newName},
} = formFields;

const validations = Yup.object().shape({
  [oldName.name]: Yup.string().required(oldName.errorMsg),
  [newName.name]: Yup.string().required(newName.errorMsg),
});

export default validations;
