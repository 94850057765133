const definitions = {
    formId: "new-user-form",
    formField: {
        userName: {
            name: "userName",
            label: "User Name",
            type: "text",
            errorMsg: "user name is required.",
        },
        callSign: {
            name: "callSign",
            label: "Call Sign",
            type: "callSign",
            errorMsg: "call Sign  is required.",
            invalidMsg: "Call Sign should be 8 characters.",
        },

        mode: {
            name: "mode",
            label: "Role",
            type: "mode",
            errorMsg: "Role  is required.",
            invalidMsg: "Your Role is invalid",
        },
        password: {
            name: "password",
            label: "Password",
            type: "password",
            errorMsg: "Password is required.",
            invalidMsg: "Your password should be more than 6 characters.",
        },
        confirmPassword: {
            name: "confirmPassword",
            label: "Confirm Password",
            type: "password",
            errorMsg: "Password is required.",
            invalidMsg: "Your password doesn't match.",
        },
        sipNumber: {
            name: "sipNumber",
            label: "Sip Number ",
            type: "text",
            errorMsg: "Sip Number is required.",
        }
    },
    errors:{
        userName:false,
        mode:false,
        password:false,
        confirmPassword:false,
    },
    touched:{
        userName:false,
        mode:false,
        password:false,
        confirmPassword:false,
    }
}
export default definitions;