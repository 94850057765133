import UserDeleteEdit from "./UserDeleteEdit";
import UserMode from "./Mode";
import ColumnIndex from "components/Helper/ColumnIndex";
import LoginStatus from "./LoginStatus";

const dataTableData = {
  
    columns: [
      { Header: "No", accessor: "id"  , Cell: (value) => <ColumnIndex data={value} />   },
      { Header: "username", accessor: "username" },
      { Header: "callSign", accessor: "callSign" },
      { Header: "Sip", accessor: "sipNumber" },
      { Header: "Role", accessor: "Role", Cell:(value) => <UserMode data={value}/> },
      { Header: "Login Status", accessor: "logStatus", Cell:(value) => <LoginStatus data={value}/> },
      { Header: "Actions", accessor: "Actions" , Cell: (value) => <UserDeleteEdit data={value}/>},
     
     
    ],
  
    
}

export default dataTableData;
