import axios from "axios";
import AuthenticationService from "services/AuthenticationService";


  

const UserService = {
    controllerName: "new-user",
    listPath: "/applications/userMaster",
    formPath: "/pages/users/new-user",
    editPath: "/pages/Module/users/Edit/EditFormContainer",
    refreshList: null,
    //auto refresh


    GetList: function () {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.get(
            window.BASE_URL_API + "/user/List", {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    Create: function (data) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.post(
            window.BASE_URL_API + "/user/register", data, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    Update: function (data) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.put(
            window.BASE_URL_API + "/user/" + data.id, data, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    Delete: function (id) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.delete(
            window.BASE_URL_API + "/user/" + id, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    Logout: function (data) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.post(
            window.BASE_URL_API + "/user" + "/logStatus" + "/out/" + data.username , null, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    }
  
}


export default UserService;