const definitions = {
    formId: "edit-user-form",
    formField: {
        userName: {
            name: "userName",
            label: "User Name",
            type: "text",
            errorMsg: "user name is required.",
        },
        callSign: {
            name: "callSign",
            label: "Call Sign",
            type: "text",
            errorMsg: "call Sign  is required.",
            invalidMsg: "Call Sign should be more than 8 characters.",
        },

        mode: {
            name: "mode",
            label: "Role",
            type: "text",
            errorMsg: "Role  is required.",
            invalidMsg: "Your Role is invalid",
        },
        sipNumber: {
            name: "sipNumber",
            label: "Sip Number",
            type: "text",
            errorMsg: "Sip Number  is required.",
           
        },
    },
    errors:{
        userName:false,
        mode:false,
        callSign:false
    },
    touched:{
        userName:false,
        mode:false,
        callSign:false
    }
}
export default definitions;