import SensorDeleteEdit from "./SensorDeleteEdit";
import StatusCell from "./sensorStatus";
import ColumnIndex from "components/Helper/ColumnIndex";
import HandlerStatus from "./HandlerStatus";


const dataTableData = {
  columns: [
    { Header: "Id", accessor: "id", width:"5%", Cell: (value) => <ColumnIndex data={value} />  },
    { Header: "type", accessor: "type" , width:"10%" },
    { Header: "url", accessor: "url", width:"10%" },
    { Header: "name", accessor: "name", width:"10%" },
    { Header: "ip", accessor: "ip", width:"10%" },
    { Header: "Sip", accessor: "sipUser", width:"10%" },
    { Header: "GPS Periodicity", accessor: "timePeriod", width:"5%"},
    { Header: "Handler Status", accessor: "handlerStatus", width:"10%" ,Cell:(value) => <HandlerStatus data = {value}/>},
    { Header: "Parent CallSign", accessor: "deviceId.description", width:"10%" },
    { Header: "Actions", accessor: "Actions", width:"5%", Cell: (value) => <SensorDeleteEdit data={value}/> },
    { Header: "status", accessor: "active", width:"10%",  Cell:(value)=> <StatusCell data={value}/> }
  ]
}
export default dataTableData;
