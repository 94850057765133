import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDButton from 'components/MDButton';
// Images
import appleIcon from "assets/images/apple-icon.png";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
//notification
import Notification from 'layouts/pages/Module/notifications/Notification';


// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import AuthenticationService from 'services/AuthenticationService';


function Cover(props) {
  const [username, setUserName] = useState(" ");
  const [password, setPassword] = useState(" ");
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("username , password")) {
    }
  }, [])

  async function login() {
    AuthenticationService.login(username, password).then((response) => {
      if (response.data.data.mode == 99) {
        AuthenticationService.storeAuthenticationToken(response.data.data.token);
        props.onLogin(!response.data.data.changedPassword);

        navigate('/Home');
      } else {
        setNotify({
          isOpen: true,
          message: "InValid Credentials",
          type: 'error'
        })
      }
    }, (error) => {
      setNotify({
        isOpen: true,
        message: error.response.data.message + " " + error.response.data.messageDetail,
        type: 'error'
      })

      if(error.response.data.data.changedPassword == false ) {
        props.onLogin(!error.response.data.data.changedPassword);
      }

      console.log(error.response.data);
    });

  }
  const HandleSubmit = e => {
    e.preventDefault();
  }


  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >



          <MDTypography variant="h4" fontWeight="medium" color="white" style={{ justifyContent: "center", display: "flex" }} >
            <MDAvatar src={appleIcon} alt="profile-image" />
          </MDTypography>
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter user name and password to Sign In
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={HandleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="userName"
                label="User Name"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setUserName(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>

            <MDBox mt={4} mb={1}>

            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" type="submit" fullWidth onClick={login}  >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Notification
          notify={notify}
          setNotify={setNotify}
        />
      </Card>
    </CoverLayout>

  );
}

export default Cover;