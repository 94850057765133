import MDButton from "components/MDButton"; 
import { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import ReactSwitch from 'react-switch';
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import Notification from "../Module/notifications/Notification";
import FormControl from "@mui/material/FormControl";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import ScreenSearchDesktopTwoToneIcon from "@mui/icons-material/ScreenSearchDesktopTwoTone";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import TextField from "@material-ui/core/TextField";
import SensorService from "services/SensorService";
// Images
import backgroundvideo from "assets/images/backgroundvideo.jpg";
import videoAvatar from "assets/images/videoAvatar.jpg";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import VideoServices from "./VideoServices";

const Header = (props) => {
  const [Sensors, setSensors] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [selected, setSelected] = useState([]);
  const [sensorSelect, setSensorSelect] = useState(" ");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [checked, setChecked] = useState(true);
  //sensorDropDown
  async function getAllSensors() {
    SensorService.GetList().then(
      (response) => {
        setSensors(response.data.data);
        setFetching(false);
      },
      (error) => {
        setFetching(false);
        console.log(error.response.data);
      }
    );
  }
  useEffect(() => {
    SensorService.refreshList = getAllSensors;
    getAllSensors();
  }, []);

  let getData = () => {
    if (props.updateList) {
      props.updateList({
        searchDate: selected,
        sensorId: sensorSelect,
      });
      setNotify({
        isOpen: true,
        message: "Data Loading in process",
        type: "info",
      });
    }
  };
  const handleChange = (event) => {
    setSensorSelect(event.target.value);
  };

  const handleChangeDate = (event) => {
    setSelected(event.target.value);
  };
  



  return (
    <MDBox position="relative" mb={1}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.3),
              rgba(gradients.info.state, 0.3)
            )}, url(${backgroundvideo})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={videoAvatar}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Video Manager
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={1} md={4} lg={2} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <FormControl variant="standard" sx={{ mt: 1, minWidth: 110 }}>
                <TextField
                  id="date"
                  type="date"
                  variant="standard"
                  label="Select Date"
                  value={selected}
                  onChange={handleChangeDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </AppBar>
          </Grid>
          <Grid item xs={1} md={4} lg={2} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <FormControl variant="standard" sx={{ mt: 1, minWidth: 110 }}>
                <InputLabel>Select Sensor</InputLabel>
                <NativeSelect value={sensorSelect} onChange={handleChange}>
                <option value={""}></option>
                  {Sensors.map((sensor) => {
                    if (sensor.type === "OnBoard Cam") {
                      return (
                        <option value={sensor.id} sensorSelect>
                          {sensor.name}
                        </option>
                      );
                    }
                    if (sensor.type === "IP Cam") {
                      return (
                        <option value={sensor.id} sensorSelect>
                          {sensor.name}
                        </option>
                      );
                    }
                  })}
                </NativeSelect>
              </FormControl>
            </AppBar>
          </Grid>
          <Grid item xs={1} md={4} lg={2} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <MDButton size="small" color="info"  onClick={() => getData()}>
                <ScreenSearchDesktopTwoToneIcon
                  color="white"
                  size="medium"
                 
                />
                &nbsp;&nbsp;&nbsp;Fetch
              </MDButton>
            </AppBar>
          </Grid>

          <Notification notify={notify} setNotify={setNotify} />
        </Grid>
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
