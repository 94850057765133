import React from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "./VideoDataTable";
import MDBox from "components/MDBox";
import { useState, useEffect  } from 'react';
import VideoServices from "./VideoServices";
import LoadingSpinner from "components/Helper/LoadingSpinner";
import Header from "./Header";
import ReactPlayer from "react-player";
import { Typography } from "@mui/material";

function Videos() {
  const [recordings, setRecordings] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [playURL, setPlayURL] = useState("");

  const getData = (data) => {
    if(data != null) {
      window.lastFilter = data;
    } else {
      data = window.lastFilter;
    }
    VideoServices.GetList(data).then(
      (response) => {
        setRecordings(response.data.data);
        setFetching(false);
      },
      (error) => {
        setFetching(false);
        console.log(error.response.data);
      }
    );
  }

  const playVideo = (data) => {
    let fullURL = window.APP_URL + "/app/v2/auth/live/" + data;
    setPlayURL(fullURL);
  }

  async function GetRecordings() {
    VideoServices.GetList().then(
      (response) => {
        setRecordings(response.data.data);
        setFetching(false);
      },
      (error) => {
        setFetching(false);
        console.log(error.response.data);
      }
    );
  }
 
  if (isFetching) {
    return <LoadingSpinner/>
  }

  VideoServices.playVideo = playVideo;

  return (
    <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <Header updateList={(data) => getData(data)}/>
      <Card>
      <DataTable
        table={{
          columns: dataTableData.columns,
          rows: recordings,
        }}
        canSearch
      />
      </Card>
      <Card>
      <Typography>{playURL}</Typography>
      <ReactPlayer
              url={playURL}
              muted={true}
              playing={true}
              Controls={true}
              onSeek={true}
              onProgress={true}
              fullwidth
              volume={1}
              width="980px"
              height="540px"
            > 
            </ReactPlayer>
            </Card>
      <Footer />
    </DashboardLayout>
  );
}
export default Videos;