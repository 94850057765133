import { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "./ConfigDataTable";
import MDBox from "components/MDBox";
import ConfigServices from "./configServices";
import Notification from "../Module/notifications/Notification";
import Header from "./Header";

function Configration() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [files, setFiles] = useState([]);
  const [isFetching, setFetching] = useState(false);

  const onSubmit = (data) => {
    let dataToSave = {
      name: data.name,
      date: data.date,
    };
    ConfigServices.Backup(dataToSave)
      .then(() => {
        setNotify({
          isOpen: true,
          message: "Backup in Process",
          type: "warning",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
        });
      });
  };

  async function fetchData() {
    ConfigServices.GetList().then(
      (response) => {
        setFiles(response.data.data);
        setFetching(false);
      },
      (error) => {
        setFetching(false);
        console.log(error.response.data);
      }
    );
  }

  useEffect(() => {
    ConfigServices.refreshList = fetchData;
    fetchData();
    const intervalTrigger = setInterval(() => ConfigServices.refreshList(), 5000);
    return () => {
      clearInterval(intervalTrigger);
    }
  }, []);

  return (
    <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <Header />
      <Card>
        {/* <MDBox p={2} lineHeight={1}>
          <MDBox
            width="100%"
            display="flex"
            justifyContent="space-between">
             <h3>Configuration</h3> 
             <MDButton
                onClick={() => setRefreshData(!refreshData)}
              >
                <Icon>refresh</Icon>
                <MDTypography variant="caption" color="secondary">
                  &nbsp;&nbsp;Refresh</MDTypography>
              </MDButton>
            </MDBox>
          </MDBox>
          <Stack spacing={1} direction="row" p={2}>
            <MDButton color="info" onClick={onSubmit}>BookMark<BackupSharpIcon fontSize="medium" /></MDButton>
            <Link to="/UploadFile">
            </Link>
            <Link to="/Export">
            <MDButton color="info" >Export<FileDownloadIcon fontSize="medium" /></MDButton>
            </Link>
            <MDButton color="info" >Import<FileUploadIcon fontSize="medium" /></MDButton>
          </Stack> */}
        <DataTable
          table={{
            columns: dataTableData.columns,
            rows: files,
          }}
          canSearch
        />
      </Card>
      <Notification notify={notify} setNotify={setNotify} />
      <Footer />
    </DashboardLayout>
  );
}
export default Configration;
