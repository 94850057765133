// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import { Stack } from "@mui/material";
import DeviceService from "./DeviceService";
import ForwardToInboxTwoToneIcon from "@mui/icons-material/ForwardToInboxTwoTone";
import deviceAvatar from "assets/images/deviceAvatar.jpg";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";

// Images
import backgroundDevice from "assets/images/backgroundDevice.jpg";
import { Icon } from "@mui/material";
import MDButton from "components/MDButton";


function Header() {
 
  return (
    <MDBox position="relative">
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.3),
              rgba(gradients.info.state, 0.3)
            )}, url(${backgroundDevice})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={deviceAvatar}
              alt="profile-image"
              size="xl"
              shadow="sm"
            ></MDAvatar>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="small">
                Device Master
              </MDTypography>
            </MDBox>
           
          </Grid>
          <Grid item xs={1} md={6} lg={2} m={1} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Stack spacing={1} direction="row">
                <MDButton fontSize="small" color="info">
                  <ForwardToInboxTwoToneIcon />
                </MDButton>
                <MDButton variant="gradient" color="info">
                  <Icon
                    fontSize="large"
                    label="Refresh"
                    onClick={() => DeviceService.refreshList()}
                  >
                    <RefreshTwoToneIcon />
                  </Icon>
                </MDButton>
              </Stack>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
