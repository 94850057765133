const formFields = {
    formId: "reset-form",
    formField: {
        username: {
            name: "username",
            label: "User Name",
            type: "text",
            errorMsg: "user name is required.",
        },
        oldPassword: {
            name: "oldPassword",
            label: "Old password",
            type: "password",
            errorMsg: "Old Password is required",
        },
        newPassword: {
            name: "newPassword",
            label: "New Password",
            type: "password",
            errorMsg: "New password is requiered",
            invalidMsg: "Your password should be more than 6 characters.",
        },
        confirmPassword: {
            name: "confirmPassword",
            label: "Confirm Password",
            type: "password",
            errorMsg: "Confirm password is requiered",
            invalidMsg: "Your password doesn't match.",
        }
    },
}
export default formFields;