import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';

function StatusCell({ active, data }) {



  const { row } = data;

  if (row.original.active === true) {
    active = <SignalWifiStatusbar4BarIcon color="success" fontSize="small" />;
  } else if (row.original.active === false) {

    active = <SignalWifiStatusbarConnectedNoInternet4Icon color="error" fontSize="small" label="active" />


  }
  return (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" fontWeight="medium" color="black" sx={{ lineHeight: 0 }}
      >
        <tooltip title="Status">
          {active}
        </tooltip>
      </MDTypography>
    </MDBox>
  );
}



export default StatusCell;