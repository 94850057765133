const formFields = {
    formId: "File-nameChange",
    formField: {
        oldName: {
            name: "oldName",
            label: "Old Name",
            type: "text",
            errorMsg: "File name is required",
        },
        newName: {
            name: "newName",
            label: "New Name",
            type: "text",
            errorMsg: "New File name is required",
        },
  
    },
}
export default formFields;