import MDButton from "components/MDButton";
import { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import Notification from "../Module/notifications/Notification";
import FormControl from "@mui/material/FormControl";
import { Stack } from "@mui/material";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import TextField from "@material-ui/core/TextField";
import NativeSelect from "@mui/material/NativeSelect";
import UserService from "../Module/users/UserService";
import MessageService from "./MessageService";
// Images
import backgroundmsg from "assets/images/backgroundmsg.jpg";
import msgAvatar from "assets/images/msgAvatar.jpg";
import InputLabel from "@mui/material/InputLabel";
import ScreenSearchDesktopTwoToneIcon from "@mui/icons-material/ScreenSearchDesktopTwoTone";
import ReactSwitch from 'react-switch';


const Header = (props) => {
  const [Messages, setMessage] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState("");
  const [senderSelect, setSenderSelect] = useState("");
  const [ReceiverSelect, setReceiverSelect] = useState("");
  const [typeSelect, setTypeSelect] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [checked, setChecked] = useState(true);

 
  

  const handleChange = (event) => {
    setSenderSelect(event.target.value);
  };

  const handleChangeDate = (event) => {
    setSelected(event.target.value);
  };

  const handleReceiverChange = (event) => {
    setReceiverSelect(event.target.value);
  };

  const handleTypeChange = (event) => {
    setTypeSelect(event.target.value);
  };

  // user
  async function getAlluser() {
    UserService.GetList().then(
      (response) => {
        setUsers(response.data.data);
        setFetching(false);
      },
      (error) => {
        setFetching(false);
        console.log(error.response.data);
      }
    );
  }

  useEffect(() => {
    UserService.refreshList = getAlluser;
    getAlluser();
  }, []);

  const fetchData = () => {
    let data = {
      date: selected,
      createdBy: senderSelect,
      sentTo: ReceiverSelect,
      messageType: typeSelect,
    };
    MessageService.GetFilterList(data).then(
      (response) => {
        props.updateList(response.data.data);
      },
      (error) => {
        setFetching(false);
        console.log(error.response.data);
      }
    );
  };

  return (
    <MDBox position="relative" mb={1}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.3),
              rgba(gradients.info.state, 0.3)
            )}, url(${backgroundmsg})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <MDAvatar
              src={msgAvatar}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Message Manager
              </MDTypography>

            </MDBox>
          </Grid>

          <Grid item xs={1} md={4} lg={2} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <TextField
                id="date"
                type="date"
                label="Select Date"
                value={selected}
                onChange={handleChangeDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </AppBar>
          </Grid>
        </Grid>
        <Stack spacing={18} p={1} margin={1} direction="row">
          <FormControl variant="standard" sx={{ minWidth: 110 }}>
            <InputLabel>Select Sender</InputLabel>
            <NativeSelect
              value={senderSelect}
              onChange={handleChange}
              defaultValue={"Select"}
            >
              <option value={""}></option>
              {users.map((user) => {
                if (user.mode == user.callSign) {
                  return (
                    <option value={user.callSign}>
                      {user.callSign}
                    </option>
                  );
                } else {
                  return <option value={user.callSign}>{user.callSign}</option>;
                }
              })}
            </NativeSelect>
          </FormControl>
          <FormControl variant="standard" sx={{ mt: 1, minWidth: 110 }}>
            <InputLabel>Select Receiver</InputLabel>
            <NativeSelect
              defaultValue=" "
              value={ReceiverSelect}
              onChange={handleReceiverChange}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <option value={""}></option>
              {users.map((user) => {
                if (user.mode == 2) {
                  return (
                    <option value={user.callSign}>{user.callSign}</option>
                  )
                } else {
                  return <option value={user.callSign}>{user.callSign}</option>;
                }
              })}
            </NativeSelect>
          </FormControl>
          <FormControl variant="standard" sx={{ mt: 1, minWidth: 110 }}>
            <InputLabel>Select Type</InputLabel>
            <NativeSelect value={typeSelect} onChange={handleTypeChange}>
              <option value={""}></option>
              <option value={"chat"}>Normal Message</option>
              <option value={"system"}>Control Message</option>
            </NativeSelect>
          </FormControl>
          <FormControl variant="standard" sx={{ mt: -1, minWidth: 110 }}>
            <MDButton color="info" size="small" onClick={() => fetchData()}>
              <ScreenSearchDesktopTwoToneIcon fontSize="medium" />
              <MDTypography color="#FFFFFF">&nbsp;&nbsp;Fetch</MDTypography>
              <Notification notify={notify} setNotify={setNotify} />
            </MDButton>
          </FormControl>
        </Stack>
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
