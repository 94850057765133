import * as Yup from "yup";
import formFields from "./ResetFormFields";

const {
  formField: {username, oldPassword, newPassword, confirmPassword},
} = formFields;

const validations = Yup.object().shape({
  [username.name]: Yup.string().required(username.errorMsg),
  [oldPassword.name]: Yup.string().required(oldPassword.errorMsg),
  [newPassword.name]: Yup.string().required(newPassword.errorMsg).min(6, newPassword.invalidMsg),
  [confirmPassword.name]: Yup.string()
    .required(confirmPassword.errorMsg)
    .oneOf([Yup.ref("newPassword"), null], confirmPassword.invalidMsg),
});

export default validations;
