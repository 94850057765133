// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { Card } from "@material-ui/core";
import { Stack } from "@mui/material";
import MDBox from "components/MDBox";

function Maps() {
  return (
    <Stack spacing={3}>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={3} pb={8}>
          <Card>
            <iframe width="100%" height="500" src="http://192.168.20.181/map/map1/#4/24.49/77.53" />
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </Stack>
  );
}

export default Maps;
