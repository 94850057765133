import axios from "axios";

const ResetService = {
  controllerName: "auth/changePassword",
  path: "/",

  Update: function (data) {
  
    return axios.put(
      window.BASE_URL_APP + "/"  + this.controllerName , data,
      
    );
  },

};

export default ResetService;