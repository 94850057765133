import DeviceDeleteEdit from "./DeviceDeleteEdit";
import StatusCell from "./deviceStatus";
import ColumnIndex from "components/Helper/ColumnIndex";
import DevicePing from "./DevicePing";


const dataTableData = {
    columns: [
      { Header: "No", accessor: "index", Cell: (value) => <ColumnIndex data={value} /> },
      { Header: "callSign", accessor: "callSign" },
      { Header: "DeviceId", accessor: "deviceId" },
      { Header: "DeviceIp", accessor: "ip"},
      { Header: "Actions", accessor: "Actions",  Cell: (value) => <DeviceDeleteEdit data={value}/> },
      { Header: "Status", accessor: "active",  Cell:(value)=> <StatusCell data={value}/> },   
      { Header: "Ping", accessor: "Ping", Cell:(value)=><DevicePing  data={value}/>},
    ],
  
    rows: [
     
      
    ]
}
export default dataTableData;
