import ColumnIndex from "components/Helper/ColumnIndex";
import VideoPlay from "./VideoPlayDelete";
const dataTableData = {
  columns: [
    { Header: "No", accessor: "id", width: "20%" , Cell: (value) => <ColumnIndex data={value} />   },
    { Header: "Name", accessor: "name", width: "30%" },
    { Header: "Action", accessor: "Action" ,width: "30%" , Cell:(value)=> <VideoPlay data={value}/> },
  ],


};
//check for dev branch 
export default dataTableData;
