import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


function UserMode ({ mode ,data }) {
  const { row } = data;
  if(row.original.mode === 99) {
  mode = "Admin" ;
  } else if (row.original.mode === 2 )  {
  mode = "Handler";
  }
  else if (row.original.mode === 1 )  {
   mode = "Operator";
   }

  return (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" fontWeight="medium" color="black" sx={{ lineHeight: 0 }}>
        {mode}
      </MDTypography>
    </MDBox>
  );


  }

export default UserMode;