import axios from "axios";
import AuthenticationService from "services/AuthenticationService";

const ResetService = {
  controllerName: "auth/changePassword",
  path: "/",

  Update: function (data) {
    let token = AuthenticationService.getAuthenticationToken();
    return axios.put(
      window.BASE_URL_APP + "/"  + this.controllerName , data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
      
    );
  },

};

export default ResetService;