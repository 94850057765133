// import { Link } from "react-router-dom";
import { useState } from "react";
import Typography from '@mui/material/Typography';
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import BackupSharpIcon from "@mui/icons-material/BackupSharp";
// import DriveFolderUploadSharpIcon from '@mui/icons-material/DriveFolderUploadSharp';
import MDButton from "components/MDButton";
import configAvatar2 from "assets/images/configAvatar2.jpg";
import Notification from "../Module/notifications/Notification";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import ConfigServices from "./configServices";
import ReactSwitch from 'react-switch';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// Images
import backgroundconfig from "assets/images/backgroundconfig.jpg";

function Header({ children }) {
  const [checked, setChecked] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  })


  const onSubmit = (data) => {
    let dataToSave = {
      name: data.name,
      date: data.date,
    };
    ConfigServices.Backup(dataToSave)
      .then(() => {
        setNotify({
          isOpen: true,
          message: "Backup in Process",
          type: "warning",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
        });
      });
  };



  const HandleChange = val => {
    setChecked(val)
    if(checked === "On") {
          const intervalTrigger = setInterval(() => ConfigServices.refreshList(), 5000);
          return () => {
            clearInterval(intervalTrigger); 
          }
  }}

  return (
    <MDBox position="relative" mb={1}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.3),
              rgba(gradients.info.state, 0.3)
            )}, url(${backgroundconfig})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={configAvatar2}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h4" fontWeight="medium">
                Configuration
              </MDTypography>
            </MDBox>
            <ReactSwitch
              checked={checked}
              onChange={HandleChange}
            />
             <Typography variant="caption" display="block" gutterBottom>
            Auto Refresh {checked ? 'On' : 'Off'}
            </Typography>
          </Grid>
          <Grid item xs={10} md={2} lg={2} sx={{ ml: "auto" }}>
            <AppBar position="static">    
                  <MDButton sx={{ mt: 1, minWidth: 110 }} color="info" onClick={onSubmit}>
                  <BackupSharpIcon fontSize="medium" color="error"  sx={{ mt: -0.25 }} />
                    BookMark
                  </MDButton>
                <Notification notify={notify} setNotify={setNotify} />
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
 {/* <Link to="/Export">
                  <MDButton sx={{ mt: 1, minWidth: 110 }} color="info" >
                <DriveFolderUploadSharpIcon fontSize="medium" color="warning"  sx={{ mt: -0.25 }} />
                  Uploadfile 
                </MDButton>
                </Link> */}