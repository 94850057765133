import { Link } from "react-router-dom";
import { useState } from "react";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import ReactSwitch from 'react-switch';
import Typography from '@mui/material/Typography';
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import userAvatar from "assets/images/userAvatar.jpg";
import UserService from "./UserService";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import backgroundUser from "assets/images/backgroundUser.jpg";
import { Icon } from "@mui/material";
import MDButton from "components/MDButton";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import { Stack } from "@mui/material";

function Header() {
  const [checked, setChecked] = useState(true);
 
  const HandleChange = val => {
    debugger;
    setChecked(val)
    if(val === "true") {
      const intervalTrigger = setInterval(() => UserService.refreshList(), 5000);
      return () => {
        clearInterval(intervalTrigger);
          }
  }}
  return (
    <MDBox position="relative" mb={1}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.2),
              rgba(gradients.info.state, 0.2)
            )}, url(${backgroundUser})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={userAvatar}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                User Master
              </MDTypography>
            </MDBox>
            <ReactSwitch
              checked={checked}
              onChange={HandleChange}
            />
             <Typography variant="caption" display="block" gutterBottom>
            Auto Refresh {checked ? 'On' : 'Off'}
            </Typography>
          </Grid>
          <Grid item xs={1} md={6} lg={2} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Stack spacing={1} direction="row" >
              <Link to={UserService.formPath}>
                <MDButton variant="gradient" color="success">
                  <Icon>
                    <AddCircleTwoToneIcon />
                  </Icon>
                </MDButton>
              </Link>
              <MDButton variant="gradient" color="info">
                <Icon
                  fontSize="large"
                  label="Refresh"
                  onClick={() => UserService.refreshList()}
                >
                  <RefreshTwoToneIcon />
                </Icon>
              </MDButton>
              </Stack>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
