import formFields from "./DeviceFormFields";

const {
    formField: {
        deviceId,
        ip,
    },
} = formFields;


export default {
    [deviceId.name]: "",
    [ip.name]: ""
}