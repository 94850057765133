import * as React from "react";
import { useState , useEffect } from "react";
import Typography from '@mui/material/Typography';
import ReactSwitch from 'react-switch';
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import dataTableData from "./DeviceDataTable";
import DataTable from "table/Tables/DataTable";
import Header from "./Header";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DeviceService from "./DeviceService";
import LoadingSpinner from "components/Helper/LoadingSpinner";

const DeviceList = () => {
  const [devices, setDevices] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [checked, setChecked] = useState(true);

  async function fetchData() {
    DeviceService.GetList().then(
      (response) => {
        setDevices(response.data.data);
        setFetching(false);
      },
      (error) => {
        setFetching(false);
        console.log(error.response.data);
      }
    );
  }

  useEffect(() => {
    DeviceService.refreshList = fetchData;
    fetchData();
  }, []);


  const HandleChange = val => {
    setChecked(val)
    if(val === "true") {
      debugger;
        const intervalTrigger = setInterval(() => DeviceService.refreshList(), 5000);
        return () => {
          clearInterval(intervalTrigger);
        }
      };
  }


  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <Header />
      <MDBox pt={1} pb={1}>
        <Card>
        <MDBox m={2} pt={1}>
        <ReactSwitch
              checked={checked}
              onChange={HandleChange}
            />
             <Typography variant="caption" display="block" gutterBottom>
            Auto Refresh {checked ? 'On' : 'Off'}
            </Typography>
            </MDBox>
          <DataTable
            table={{
              columns: dataTableData.columns,

              rows: devices,
            }}
            canSearch
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};
export default DeviceList;
