import * as React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import tooltip from "assets/theme/components/tooltip";
//list
import { IconButton } from "@material-ui/core";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import SensorService from "./SensorService";
import MDBox from "components/MDBox";
import Notification from "../notifications/Notification";
import ConfirmDialog from "../notifications/ConfirmDialog";
import LoadingSpinner from "components/Helper/LoadingSpinner";

function SensorDeleteEdit({ data }) {
  const { row } = data;
  const [sensor, setSensors] = useState([]);
  const [isFetching, setFetching] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    SensorService.Delete(id).then(() => {
      SensorService.GetList()
        .then((response) => {
          setSensors(response.data.data);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
          SensorService.refreshList();
          setFetching(false);
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
          });
        });
    });
  };

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <MDBox m={-3}>
      <Link
        to={SensorService.formPath}
        state={{
          hasFormInfo: true,
          id: row.original.id,
          type: row.original.type,
          handlerStatus:row.original.handlerStatus,
          url: row.original.url,
          name: row.original.name,
          sipUser:row.original.sipUser,
          ip: row.original.ip,
          timePeriod: row.original.timePeriod,
          deviceId: row.original.deviceId.id,
        }}
      >
        {" "}
        <tooltip title="Edit">
        <IconButton key={sensor.id}>
          <ModeEditOutlineIcon color="info" fontSize="small" />
        </IconButton></tooltip>{" "}
      </Link>
      <tooltip title="Delete">
      <IconButton
        onClick={() => {
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure to delete this record?",
            subTitle: row.original.name,
            onConfirm: () => {
              handleDelete(row.original.id);
            },
          });
        }}
      >
        <DeleteTwoToneIcon color="error" fontSize="medium" />
      </IconButton>
      </tooltip>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default SensorDeleteEdit;
