import * as React from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import MDBox from 'components/MDBox';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import VideoServices from './VideoServices';
import ReactPlayer from "react-player";
import PlayArrowRounded from '@material-ui/icons/PlayArrowRounded';
import { IconButton } from "@material-ui/core";

const Play = () => {

    const [recordings, setRecordings] = useState([]);
    const [isFetching, setFetching] = useState(false);
    const [video, setVideo] = useState("");

    const navigate = useNavigate();
    const [playURL, setPlayURL] = useState("");

    const getData = (data) => {
      VideoServices.GetList(data).then(
        (response) => {
          setRecordings(response.data.data);
          setFetching(false);
        },
        (error) => {
          setFetching(false);
          console.log(error.response.data);
        }
      );
    }
  
    const playVideo = (data) => {
      let fullURL = window.APP_URL + "/app/v2/auth/live/" + data;
      setPlayURL(fullURL);
    }
  
    async function GetRecordings() {
      VideoServices.GetList().then(
        (response) => {
          setRecordings(response.data.data);
          setFetching(false);
        },
        (error) => {
          setFetching(false);
          console.log(error.response.data);
        }
      );
    }
  
  //auto refresh
  useEffect(() => {
   
      VideoServices.refreshList = GetRecordings;
      GetRecordings();
  }, []);
  VideoServices.playVideo = playVideo;

  
    return (
      <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
      </MDBox>
      <Card>
      
                  <ReactPlayer
              url={playURL}
              muted={true}
              playing={true}
              controls={true}
              onSeek={true}
              onProgress={true}
              fullwidth
              volume={1}
              width="980px"
              height="540px"
            >
            </ReactPlayer>
            <IconButton onClick={() => {
                    VideoServices.playVideo();
                }} >
                    <PlayArrowRounded color="disabled"/>
                </IconButton>
      </Card>
      <Footer />
    </DashboardLayout>
    );
}
export default Play;