import * as React from "react";
import { useState , useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Header from "./Header";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import dataTableData from "./SensorDataTable";
import SensorService from "./SensorService";
import DataTable from "table/Tables/DataTable";
import LoadingSpinner from "components/Helper/LoadingSpinner";



const SensorList = () => {
  const [Sensors, setSensors] = useState([]);
  const [isFetching, setFetching] = useState(false);


  async function fetchData() {
    SensorService.GetList().then(
      (response) => {
        setSensors(response.data.data);
        setFetching(false);
      },
      (error) => {
        setFetching(false);
        console.log(error.response.data);
      }
    );
  }
  useEffect(() => {
    SensorService.refreshList = fetchData;
    fetchData();
    const intervalTrigger = setInterval(() => SensorService.refreshList(), 5000);
    return () => {
      clearInterval(intervalTrigger);
    }
  }, []);







  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <Header />
      <Card>
        {/* <MDBox p={2} lineHeight={1}>
            <MDBox
            width="100%"
            display="flex"
            justifyContent="space-between">
             <h3> Sensor Master </h3>
              <MDButton
                onClick={() => setRefreshData(!refreshData)}
              >
                <Icon>refresh</Icon>
                <MDTypography variant="caption" color="secondary">
                  &nbsp;&nbsp;Refresh</MDTypography>
              </MDButton>
            </MDBox>
            <Link to={SensorService.formPath}>
              <MDTypography variant="button" color="text">
                <MDButton>ADD Sensor</MDButton>
              </MDTypography>
            </Link>
          </MDBox> */}
        <DataTable
          table={{
            columns: dataTableData.columns,
            rows: Sensors,
          }}
          canSearch
        />
      </Card>
      <Footer />
    </DashboardLayout>
  );
};

export default SensorList;
