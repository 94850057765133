import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "./ResetInputControls";



function ResetForm({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { username, oldPassword, newPassword, confirmPassword } = formField;
  const {
    username: usernameV,
    oldPassword: oldPasswordV,
    newPassword: newPasswordV,
    confirmPassword: confirmPasswordV
  } = values;

  const submitActionName = "Update" 
  
  return (
    <MDBox>
      <MDBox m={3}>
        <FormField
          disabled={true}
          name={username.name}
          variant="outlined"
          fullWidth
          id={username.name}
          label={username.label}
          type={username.type}
          placeholder={username.placeholder}
          error={errors.username && touched.username}
          success={!errors.username}
          value={usernameV}
        />
      </MDBox>
      <MDBox m={3}>
        <FormField
          name={oldPassword.name}
          variant="outlined"
          fullWidth
          id={oldPassword.name}
          label={oldPassword.label}
          type={oldPassword.type}
          placeholder={oldPassword.placeholder}
          error={errors.oldPassword && touched.oldPassword}
          success={!errors.oldPassword}
          value={oldPasswordV}
        />
      </MDBox>
      <MDBox m={3}>
        <FormField
          name={newPassword.name}
          variant="outlined"
          fullWidth
          id={newPassword.name}
          label={newPassword.label}
          type={newPassword.type}
          placeholder={newPassword.placeholder}
          error={errors.newPassword && touched.newPassword}
          success={!errors.newPassword}
          value={newPasswordV}
        />
      </MDBox>
      <MDBox m={3}>
        <FormField
          name={confirmPassword.name}
          variant="outlined"
          fullWidth
          id={confirmPassword.name}
          label={confirmPassword.label}
          type={confirmPassword.type}
          placeholder={confirmPassword.placeholder}
          error={errors.confirmPassword && touched.confirmPassword}
          success={!errors.confirmPassword}
          value={confirmPasswordV}
        />
      </MDBox>
      <MDBox p={2}>
        <MDBox>
          <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
            <MDBox />
            <MDButton type="submit" variant="contained" color="info" fullWidth >{submitActionName}</MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>

  );
}

export default ResetForm;