import axios from "axios";

const AuthenticationService = {
    storeAuthenticationToken: function(value) {
        localStorage.setItem("X-Token", value);
    },
    getAuthenticationToken: function() {
        return localStorage.getItem("X-Token");
    },
    getUserName: function() {
        return localStorage.getItem("X-User");
    },
    logout:function() {
        localStorage.setItem("X-Token", "");
    },
    login: function(userName, password) {
        localStorage.setItem("X-User", userName);
        return axios.post(
            window.BASE_URL_APP + "/auth/login",
            {
                username : userName,
                password : password,
            }
          );
    }
};

export default AuthenticationService;