import * as Yup from "yup";
import formFields from "./DeviceFormFields";

const {
  formField: { deviceId, ip },
} = formFields;

const validations = Yup.object().shape({
  [deviceId.name]: Yup.string().required(deviceId.errorMsg),
  [ip.name]: Yup.string().required(ip.errorMsg).matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/,"Invalid IP address"),
});

export default validations;
