import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "../UserInputControls";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';



function Edit({ formData }) {
  const { formField, values, errors, touched, handleChange } = formData;
  const { userName, callSign, mode ,sipNumber } = formField;
  const {
    userName: userNameV,
    mode : modeV,
    callSign: callSignV,
    sipNumber: sipNumberV
  } = values;
  
  
  return (
    <MDBox>
      <MDBox m={3}>
        <FormField
          name={userName.name}
          variant="outlined"
          fullWidth
          id={userName.name}
          label={userName.label}
          type={userName.type}
          placeholder={userName.placeholder}
          error={errors.userName && touched.userName}
          success={!errors.userName}
          value={userNameV}
        />
      </MDBox>
      <MDBox m={3}>
        <FormField
          name={callSign.name}
          variant="outlined"
          fullWidth
          id={callSign.name}
          label={callSign.label}
          type={callSign.type}
          placeholder={callSign.placeholder}
          value={callSignV}
          error={errors.callSign && touched.callSign}
        />
      </MDBox>
      <MDBox m={3}>
        <MDBox>
          <InputLabel >Role</InputLabel>
          <Select
            m={3}
            value={modeV}
            onChange={handleChange}
            label="Role"
            fullWidth
            variant="standard"
            name={mode.name}
            id={mode.name}
            type={mode.type}
            placeholder={mode.placeholder}
          >
            <MenuItem value={1}>Operator</MenuItem>
            <MenuItem value={2}>Handler</MenuItem>
          </Select>
        </MDBox> 
      </MDBox>
      <MDBox m={3}>
        <FormField
          name={sipNumber.name}
          variant="outlined"
          fullWidth
          id={sipNumber.name}
          label={sipNumber.label}
          type={sipNumber.type}
          placeholder={sipNumber.placeholder}
          value={sipNumberV}
          error={errors.sipNumber && touched.sipNumber}
          success={!errors.sipNumber}
        />
      </MDBox>
      <MDBox p={2}>
        <MDBox>
          <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
            <MDBox />
            <MDButton type="submit" variant="contained" color="info" fullWidth>Update</MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default Edit;