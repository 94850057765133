import axios from "axios";
import AuthenticationService from "services/AuthenticationService";

const MessageService = {
  controllerName: "message",

refreshList:null,
GetList: function () {
  let token = AuthenticationService.getAuthenticationToken();
  return axios.get(
    window.BASE_URL_API + "/" + this.controllerName + "/List",
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
},
Delete: function (id) {
  let token = AuthenticationService.getAuthenticationToken();
  return axios.delete(
    window.BASE_URL_API + "/" + this.controllerName + "/" + id,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
},
GetFilterList: function (data) {
  let token = AuthenticationService.getAuthenticationToken();
  return axios.post(
    window.BASE_URL_API + "/" + this.controllerName + "/findByFilters", data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
},
  
};

export default MessageService;
