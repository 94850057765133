import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "./DeviceInputControls";
import DeviceService from "./DeviceService";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";

function DeviceForm({ formData }) {
  const { formField, values, errors, touched, isEdit } = formData;
  const { deviceId, ip } = formField;
  const { deviceId: deviceIdV, ip: ipV } = values;
  const submitActionName = isEdit ? "Update" : "Add";
  const navigate = useNavigate();
  const Back = () => {
    navigate(DeviceService.listPath);
  };

  return (
    <MDBox>
      <MDBox m={3}>
        <FormField
          name={deviceId.name}
          variant="outlined"
          fullWidth
          id={deviceId.name}
          label={deviceId.label}
          type={deviceId.type}
          placeholder={deviceId.placeholder}
          error={errors.deviceId && touched.deviceId}
          success={!errors.deviceId}
          value={deviceIdV}
        />
      </MDBox>
      <MDBox m={3}>
        <FormField
          name={ip.name}
          variant="outlined"
          fullWidth
          id={ip.name}
          label={ip.label}
          type={ip.type}
          placeholder={ip.placeholder}
          error={errors.ip && touched.ip}
          success={!errors.ip}
          value={ipV}
        />
      </MDBox>
      <Stack spacing={2} p={2} direction="row">
        <MDButton type="submit" variant="contained" color="info"  fullWidth onClick={Back}>
          Back
        </MDButton>
        <MDBox />
        <MDButton type="submit" variant="contained"  fullWidth color="info">
          {submitActionName}
        </MDButton>
      </Stack>
    </MDBox>
  );
}

export default DeviceForm;
