import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function LoginStatus ({ data }) {
    let loginStatus = data.row.original.logStatus ? "Logged In" : "Logged Out";
    
    return (
        <MDBox display="flex" alignItems="center">
          <MDTypography variant="caption" fontWeight="medium" color="black" sx={{ lineHeight: 0 }}>
            {loginStatus}
          </MDTypography>
        </MDBox>
      );
}


export default LoginStatus;