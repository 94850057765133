import * as Yup from "yup";
import formFields from "./SensorFormFields";

const {
  formField: { type, name, deviceId, ip, timePeriod },
} = formFields;

const validations = Yup.object().shape({
  [type.name]: Yup.string().required(type.errorMsg),
  [name.name]: Yup.string().required(name.errorMsg),
  [deviceId.name]: Yup.string().required(deviceId.errorMsg),
  [timePeriod.name]: Yup.string().required(timePeriod.errorMsg),
  [ip.name]: Yup.string().required(ip.errorMsg)
  .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/,"Invalid IP address"),
});

export default validations;
