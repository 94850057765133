const ColumnIndex = ({ data }) => {
    const { row } = data
    let index = data.state.pageIndex * data.state.pageSize;
    
    let i = 0;

    for (; i < data.page.length; i++) {
      
        if (data.page[i].id == row.id) {
            
            index += i + 1;
         
            break;
        }
   
    }

    return (
        <>
            {index}
        </>
    )
}

export default ColumnIndex;