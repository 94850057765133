// Material Dashboard 2 PRO React layouts
import Home from "layouts/dashboards/Home";
import Maps from "layouts/dashboards/Maps";
import UserList from "layouts/pages/Module/users/UserList";
import Cover from "layouts/authentication/reset-password";
import UserFormContainer from "layouts/pages/Module/users/UserFormContainer";
import DeviceList from "layouts/pages/Module/device/DeviceList";
import DeviceFormContainer from "layouts/pages/Module/device/DeviceFormContainer";
import SensorList from "layouts/pages/Module/sensor/SensorList";
import EditFormContainer from "layouts/pages/Module/users/Edit/EditFormContainer";
import Videos from "layouts/pages/Videos/VideosContainer";
import Message from "layouts/pages/Message";
import Export from "layouts/pages/configration/Export";

// @mui icons
import Icon from "@mui/material/Icon";
import SensorFormContainer from "layouts/pages/Module/sensor/SensorFormContainer";
import Configration from "layouts/pages/configration";
import ChangeFileName from "layouts/pages/configration/changeFileName";
import AdminReset from "layouts/authentication/AdminReset-password";
import Play from "layouts/pages/Videos/Play";

const routes = [
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    display: false,
    collapse: [
      {
        name: "Home",
        key: "Home",
        route: "/dashboards/Home",
        component: <Home />,
      },
      {
        name: "Maps",
        key: "Maps",
        route: "/dashboards/Maps",
        component: <Maps />,
      },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages", display: false },
  {
    type: "collapse",
    name: "Pages",
    key: "pages",
    icon: <Icon fontSize="medium">image</Icon>,
    display: false,
    collapse: [
      {
        name: "Users",
        key: "users",
        collapse: [
          {
            name: "New User",
            key: "new-user",
            route: "/pages/users/new-user",
            component: <UserFormContainer />,
          },
          {
            name: "Sensor",
            key: "Sensor",
            route: "/pages/Sensor",
            component: <SensorFormContainer />,
          },
          {
            name: "Device",
            key: "Device",
            route: "/pages/AddDevice",
            component: <DeviceFormContainer />,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    display: true,
    collapse: [
      {
        name: "User Master",
        key: "userMaster",
        route: "/applications/userMaster",
        display: true,
        component: <UserList />,
      },
      {
        name: "Device Master",
        key: "deviceMaster",
        route: "/applications/deviceMaster",
        display: true,
        component: <DeviceList />,
      },
      {
        name: "Sensor Master",
        key: "sensorMaster",
        route: "/applications/sensorMaster",
        display: true,
        component: <SensorList />,
      },
      {
        name: "Video Manager",
        key: "Videos",
        display: true,
        icon: <Icon fontSize="medium">apps</Icon>,
        route: "/applications/videos",
        component: <Videos />,
      },
      {
        name: "Configuration",
        display: true,
        icon: <Icon fontSize="medium">apps</Icon>,
        route: "/applications/Configuration",
        component: <Configration />,
      },
      {
        name: "Message Manager",
        display: true,
        icon: <Icon fontSize="medium">apps</Icon>,
        route: "/applications/message",
        component: <Message />,
      },
      {
        name: "Change Password",
        display: true,
        icon: <Icon fontSize="medium">apps</Icon>,
        route: "/applications/authentication/reset-password",
        component: <Cover />,
      },
      {
        name: "User Password Reset ",
        display: false,
        icon: <Icon fontSize="medium">apps</Icon>,
        route: "/adminResetPassword",
        component: <AdminReset />,
      },
     
      
      {
        name: "Export",
        key: "Export",
        display: false,
        route: "/Export",
        component: <Export />,
      },
      {
        name: "Change File Name",
        display: false,
        route: "/ChangeFileName",
        component: <ChangeFileName />,
      },
     
      {
        name: "Edit User",
        display: false,
        route: "/pages/Module/users/Edit/EditFormContainer",
        component: <EditFormContainer />,
      },
  
      {
        name: "Play Video",
        key: "PlayVideo",
        route: "/PlayVideo",
        display: false,
        component: <Play />,
      },
    ],
  },
];

export default routes;
