import formFields from "./SensorFormFields";
const {
  formField: {
    type, url, name, ip,  deviceId, timePeriod , handlerStatus , sipUser},
  
} = formFields;



export default {
  [type.name]: "",
  [url.name]: "",
  [name.name]: " ",
  [ip.name]: "",
  [sipUser.name]: "",
  [deviceId.name]: "",
  [timePeriod.name]: "",
  [handlerStatus.name]: "",
}