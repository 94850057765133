// @mui material components
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useState } from "react";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//Device

//formfield
import { useNavigate, useLocation } from "react-router-dom";
import Notification from "../notifications/Notification";
import SensorForm from "./SensorForm";
import formFields from "./SensorFormFields";
import SensorInitialValues from "./SensorInitialValues";
import validations from "./SensorValidations";
import SensorService from "./SensorService";

function SensorFormContainer() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const navigate = useNavigate();
  const { formField } = formFields;
  const location = useLocation();
  const defaultValues = { ...SensorInitialValues };
  if (location.state) {
    if (location.state.hasFormInfo) {
      defaultValues.id = location.state.id
      defaultValues.ip = location.state.ip;
      defaultValues.url = location.state.url;
      defaultValues.type = location.state.type;
      defaultValues.handlerStatus = location.state.handlerStatus;
      defaultValues.name = location.state.name;
      defaultValues.timePeriod = location.state.timePeriod;
      defaultValues.deviceId = location.state.deviceId;
      defaultValues.sipUser =  location.state.sipUser;
    } else {
      defaultValues.id = 0;
    }
  }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper alternativeLabel>
                  <Step>
                    <StepLabel>Sensor</StepLabel>
                  </Step>
                </Stepper>

                <Formik
                  validationSchema={validations}
                  initialValues={defaultValues}
                  onSubmit={(data) => {


                    let dataToSave = {
                      id:data.id,
                      ip: data.ip,
                      timePeriod:data.timePeriod,
                      url: data.url,
                      name: data.name,
                      type: data.type,
                      sipUser: data.sipUser,
                      handlerStatus:data.handlerStatus,
                      deviceId: {
                        id: data.deviceId
                      }
                    }


                    if (data.id) {
                      SensorService.Update(dataToSave).then(() => {
                        setNotify({
                          isOpen: true,
                          message: "updated Successfully",
                          type: "success",
                        });
                        navigate(SensorService.listPath);
                      }).catch(error => {
                        setNotify({
                          isOpen: true,
                          message:error.response.data.message +" "+ error.response.data.messageDetail ,
                          type: 'error'
                        })});
                    } else {
                      SensorService.Create(dataToSave).then(() => {
                        setNotify({
                          isOpen: true,
                          message: "Submitted Successfully",
                          type: "success",
                        });
                        navigate(SensorService.listPath);
                      }).catch(error => {
                        setNotify({
                          isOpen: true,
                          message:error.response.data.message +" "+ error.response.data.messageDetail ,
                          type: 'error'
                        })});
                    }
                  }}

                >
                  {({ values, errors, touched, isSubmitting, handleChange  }) => (
                    <Form>
                      <SensorForm
                        formData={{
                          values,
                          errors,
                          formField,
                          touched,
                          isSubmitting,
                          handleChange,
                          isEdit: defaultValues.id ? true : false,
                        }}
                      />
                    </Form>
                  )}
                </Formik>
                <Notification notify={notify} setNotify={setNotify} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SensorFormContainer;
