import React from "react";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Grid from "@mui/material/Grid";
import MDDropzone from "components/MDDropzone";
import { useMemo } from "react";
import { Stack } from "@mui/material";
import ConfigServices from "./configServices";


export default function Export(data) {
  const { row } = data;
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
  const navigate = useNavigate();
  const Back = () =>{
    navigate("/Configuration");
  }


  const Upload = () => {
   
    let dataToSave = {
      name: data.name,
    }
    ConfigServices.Upload(dataToSave).then(() => {
      setNotify({
        isOpen: true,
        message: "File Successfully Upload",
        type: "success",
      });
  
    }).catch(error => {
      setNotify({
        isOpen: true,
        message: error.response.data.message + " " + error.response.data.messageDetail,
        type: 'error'
      })
    });
  }
    return (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={3} pb={8}>
            <Grid container justifyContent="center" sx={{ my: 4 }}>
              <Grid item xs={12} lg={8}>
                <Card>
                  <MDBox mt={-3} mx={2}>
                    <Stepper alternativeLabel>
                      <Step>
                        <StepLabel>Upload File</StepLabel>
                      </Step>
                    </Stepper>
                    <Stack spacing={1} direction="column" p={2} m={3}>
                    <MDBox mb={2}>
                    <MDBox mb={2} display="inline-block">
                      <h4>Choose File</h4>
                    </MDBox>
                    {useMemo( 

                      () => (
                        <MDDropzone options={{ addRemoveLinks: true }} />
                      ),
                      []
                    )}
                  </MDBox>
                  </Stack>
                
                  <Stack spacing={2} p={2} direction="row">
        <MDButton type="submit" variant="contained" color="info"  fullWidth onClick={Back}>
          Back
        </MDButton>
        <MDBox />
        <MDButton type="submit" variant="contained"  fullWidth color="info" onClick={Upload}>
          Upload
        </MDButton>
      </Stack>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}