// @mui material components
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik"
import { useState} from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Notification from "layouts/pages/Module/notifications/Notification";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//formfield
import { useNavigate, useLocation } from "react-router-dom";

import ResetForm from "./ResetForm";
import formFields from "./ResetFormFields";
import ResetInitialValues from "./ResetInitialValues";
import validations from "./ResetValidations";
import ResetService from "./ResetServices";
import AuthenticationService from "services/AuthenticationService";

function Cover(props) {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
  const location = useLocation();
  const navigate = useNavigate();
  const { formField } = formFields;
  const defaultValues = { ...ResetInitialValues }


  if (location.state) {
    if (location.state.hasFormInfo) {
      defaultValues.username = location.state.userName
    } else {
      defaultValues.username = AuthenticationService.getUserName();
    }
  } else {
    defaultValues.username = AuthenticationService.getUserName();
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper alternativeLabel>
                  <Step>
                    <StepLabel>Change Admin Password</StepLabel>
                  </Step>
                </Stepper>
                <Formik
                  validationSchema={validations}
                  initialValues={defaultValues}
                  onSubmit={(data) => {
                    let dataToSave = {
                      username: data.username,
                      oldPassword: data.oldPassword,
                      newPassword: data.newPassword,
                      confirmPassword: data.confirmPassword,
                    }
                 
                    if (dataToSave) {
                      ResetService.Update(dataToSave).then(() => {
                        setNotify({
                          isOpen: true,
                          message: (AuthenticationService.getUserName() === dataToSave.username ? "Reset Successfully. Signing out." : "Reset Successful for " + dataToSave.username),
                          type: 'success'
                        });

                        if(AuthenticationService.getUserName() === dataToSave.username) {
                          setTimeout(function () { 
                            navigate("/");
                            window.location.reload();
                          }, 1500);  
                        } else {
                          setTimeout(function () { 
                            navigate("/applications/userMaster");
                          }, 1500);  
                        }


                      }).catch(error => {
                        setNotify({
                          isOpen: true,
                          message: error.response.data.message + " " + error.response.data.messageDetail,
                          type: 'error'
                        })

                      });
                    }
                  }}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form>
                      <ResetForm formData={{
                        values,
                        errors,
                        formField,
                        touched,
                        isSubmitting,
                      }} />
                    </Form>
                  )}
                </Formik>

                <Notification
                  notify={notify}
                  setNotify={setNotify}
                />
              </MDBox>

            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Cover;