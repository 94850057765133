const formFields = {
    formId: "device-form",
    formField: {
        deviceId: {
            name: "deviceId",
            label: "Device Id",
            type: "text",
            errorMsg: "Device Address is required",
        },
        ip: {
            name: "ip",
            label: "IP Address",
            type: "text",
            errorMsg: "IP Address is required",
        }
    },
}
export default formFields;