// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


function HandlerStatus({ handlerStatus ,data }) {



  const { row } = data;
 
  if(row.original.handlerStatus === "off") {
    handlerStatus = "Disable"
  } else if (row.original.handlerStatus === "tx") {
   
    handlerStatus = "Only Tx";
  
   
  }
  else if (row.original.handlerStatus === "rx") {
    handlerStatus = "With Rx";
  }
  return (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" fontWeight="medium" color="black" sx={{ lineHeight: 0 }}
      >  
        {handlerStatus}
      </MDTypography>
    </MDBox>
  );
}



export default HandlerStatus;