import * as StompJs from '@stomp/stompjs';

let client = null;

const WebSocketService = {
    init : function(subscribeUrl, topic, callback) {
        if(client == null) {
            client = new StompJs.Client({
              brokerURL: subscribeUrl,
              debug: function (str) {
                console.log(str);
              },
              reconnectDelay: 5000,
              heartbeatIncoming: 4000,
              heartbeatOutgoing: 4000,
            });
          
            client.activate();
          
            client.onConnect = function (frame) {
              const subscription = client.subscribe(topic, callback);
          
              console.log(subscription.id);
            };
        }
    }
}

export default WebSocketService;