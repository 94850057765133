import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';


const MsgLength = ({ data }) => {
    const { row } = data
    const count = 48;  
const result = row.original.message.slice(0, count) + (row.original.message.length > count ? "..." : "");  
const [anchorEl, setAnchorEl] = React.useState(null);

const handlePopoverOpen = (event) => {
  setAnchorEl(event.currentTarget);
};

const handlePopoverClose = () => {
  setAnchorEl(null);
};

const open = Boolean(anchorEl);

    return (
      <div>
      <Typography
        align="center"  variant="text"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
  {result}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
           <Typography bgcolor="skyblue" variant="h6" sx={{ p: 2 }}>   {row.original.message}</Typography>
    
      </Popover>
    </div>
       
          
   

    )
}

export default MsgLength;