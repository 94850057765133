import axios from "axios";
import AuthenticationService from "services/AuthenticationService";

const ConfigServices = {
    controllerName: "backupRestore",


    refreshList: null,

    GetList: function () {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.get(
            window.BASE_URL_API + "/" + this.controllerName + "/List",
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },

    Backup: function (data) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.post(
            window.BASE_URL_API + "/" + this.controllerName + "/backup", data, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    Restore: function (data) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.post(
            window.BASE_URL_API + "/" + this.controllerName + "/restore", data, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    Create: function (data) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.post(
            window.BASE_URL_API + "/" + this.controllerName + "/changeName", data, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    Upload: function (data) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.post(
            window.BASE_URL_APP  + "/file" + "/uploadFile", data, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    Download: function (data) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.get(
            window.BASE_URL_APP + "/auth" + "/backup/" + data.name, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    DeleteFile: function (fileName) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.get(
            window.BASE_URL_API + "/" + this.controllerName + "/delete/" + fileName,
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
}
export default ConfigServices