import ColumnIndex from "components/Helper/ColumnIndex";
import MessageDelete from "./MessageDelete";
import MsgLength from "./MsgLength";
import IdCell from "./IdCell";

const dataTableData = {
    columns: [
      { Header: "Select", accessor: "id", Cell: ({ value }) => <IdCell id={value} /> },
      { Header: "ID", accessor: "Id", Cell: (value) => <ColumnIndex data={value} />  },
      { Header: "Sender Id", accessor:  "createdBy"},
      { Header: "Message", accessor: "msg", Cell: (value) => <MsgLength data={value} /> },
      { Header: "Receiver Id", accessor: "sentTo" },
      { Header: "Message Type", accessor: "messageType"  }, 
      { Header: "Created On", accessor: "createdOn"  },    
      { Header: "Action", accessor: "Action",  Cell: (value) => <MessageDelete data={value} /> },    
    ],
    
}
export default dataTableData;
