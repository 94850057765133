// @mui material components
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";
import { useState } from "react";
import Notification from "../notifications/Notification";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//formfield
import { useNavigate, useLocation } from "react-router-dom";

import DeviceForm from "./DeviceForm";
import formFields from "./DeviceFormFields";
import DeviceInitialValues from "./DeviceInitialValues";
import validations from "./DeviceValidations";
import DeviceService from "./DeviceService";

function DeviceFormContainer() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { formField } = formFields;
  const defaultValues = { ...DeviceInitialValues };

  if (location.state) {
    if (location.state.hasFormInfo) {
      defaultValues.id = location.state.id;
      defaultValues.deviceId = location.state.deviceId;
      defaultValues.ip = location.state.ip;
    } else {
      defaultValues.id = 0;
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper alternativeLabel>
                  <Step>
                    <StepLabel>Device</StepLabel>
                  </Step>
                </Stepper>
                <Formik
                  validationSchema={validations}
                  initialValues={defaultValues}
                  onSubmit={(data) => {
                    let dataToSave = {
                      id: data.id,
                      deviceId: data.deviceId,
                      ip: data.ip,
                    };

                    if (dataToSave.id) {
                      DeviceService.Update(dataToSave)
                        .then(() => {
                          setNotify({
                            isOpen: true,
                            message: "updated Successfully",
                            type: "success",
                          });
                          navigate(DeviceService.listPath);
                        })
                        .catch((error) => {
                          setNotify({
                            isOpen: true,
                            message:
                              error.response.data.message +
                              " " +
                              error.response.data.messageDetail,
                            type: "error",
                          });
                        });
                    } else {
                      DeviceService.Create(dataToSave)
                        .then(() => {
                          setNotify({
                            isOpen: true,
                            message: "Submitted Successfully",
                            type: "success",
                          });
                          navigate(DeviceService.listPath);
                        })
                        .catch((error) => {
                          setNotify({
                            isOpen: true,
                            message:
                              error.response.data.message +
                              " " +
                              error.response.data.messageDetail,
                            type: "error",
                          });
                        });
                    }
                  }}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form>
                      <DeviceForm
                        formData={{
                          values,
                          errors,
                          formField,
                          touched,
                          isSubmitting,
                          isEdit: defaultValues.id ? true : false,
                        }}
                      />
                    </Form>
                  )}
                </Formik>
                <Notification notify={notify} setNotify={setNotify} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DeviceFormContainer;
