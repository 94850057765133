import * as Yup from "yup";
import definitions from "./UserFormFields";

const {
  formField: { userName, callSign,  mode, password, confirmPassword },
} = definitions;

const validations = 
  Yup.object().shape({
    [userName.name]: Yup.string().required(userName.errorMsg),
    [callSign.name]: Yup.string().required(callSign.errorMsg).min(8, callSign.invalidMsg).max(8),
    [mode.name]: Yup.string().required(mode.errorMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
    [confirmPassword.name]: Yup.string()
      .required(confirmPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], confirmPassword.invalidMsg),
  });

export default validations;