const formFields = {
    formId: "Sensor-form",
    formField: {
       type: {
            name: "type",
            label: "Type",
            type: "text",
            errorMsg: "Type is required",
        },
        url: {
            name: "url",
            label: "Url",
            type: "text",
            errorMsg:'Must be a valid URL',
        },
        name: {
            name: "name",
            label: "Name",
            type: "text",
            errorMsg: "Name is required",
        },
        ip: {
            name: "ip",
            label: "Ip",
            type: "text",
            errorMsg: "IP Address is required",
        },
        timePeriod :{
            name: "timePeriod",
            label: "Gps Periodicity",
            type: "text",
            errorMsg: "Gps Periodicity is required",
        },
        deviceId: {
            name: "deviceId",
            label: "Device Id",
            type: "text",
            errorMsg: "Parent Device id is required",
        } ,
        handlerStatus: {
            name: "handlerStatus",
            label: "Handler Status",
            type: "text",
            errorMsg: "Handler Status is required",
        } ,  
        sipUser: {
            name: "sipUser",
            label: "Sip User",
            type: "text",
            errorMsg: "Sip User is required",
        } ,       
    },
}
export default formFields;