import axios from "axios";
import AuthenticationService from "services/AuthenticationService";

const DeviceService = {
    controllerName: "device",
    listPath:"/applications/deviceMaster",
    formPath:"/pages/AddDevice",
    refreshList:null,
    GetList: function () {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.get(
            window.BASE_URL_API + "/" + this.controllerName + "/List", {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    Create: function (data) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.post(
            window.BASE_URL_API + "/" + this.controllerName + "/register", data, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    Update: function (data) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.put(
            window.BASE_URL_API + "/" + this.controllerName + "/" + data.id, data, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    Delete: function (id) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.delete(
            window.BASE_URL_API + "/" + this.controllerName + "/" + id, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    }
}

export default DeviceService;