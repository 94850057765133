import axios from "axios";
import AuthenticationService from "services/AuthenticationService";

const VideoServices = {
  controllerName: "recordings",
  playVideo : null,
  refreshList: null,
  getFilterData: null,

  GetList: function (data) {
    if (data == null) {
      data = {
        searchDate: "2022-08-27",
        sensorId: 0
      }
    }
    let token = AuthenticationService.getAuthenticationToken();
    return axios.post(
      window.BASE_URL_API + "/" + this.controllerName + "/List", data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  },

  DeleteFile: function (fileName) {
    let token = AuthenticationService.getAuthenticationToken();
    return axios.get(
        window.BASE_URL_API + "/" + this.controllerName + "/delete/" + fileName,
        {
            headers: {
                Authorization: "Bearer " + token,
            },
        }
    );
},

};

export default VideoServices;
