import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import MDBox from "components/MDBox";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import LockResetIcon from "@mui/icons-material/LockReset";
import { IconButton } from "@material-ui/core";
import UserService from "./UserService";
//confirm Dialogue box
import Notification from "../notifications/Notification";
import ConfirmDialog from "../notifications/ConfirmDialog";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import LoadingSpinner from "components/Helper/LoadingSpinner";



const UserDeleteEdit = ({ data }) => {
  const { row } = data;
  const [isFetching, setFetching] = useState(false);
  const [users, setUsers] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const navigate = useNavigate();

  const logout = (username) => {
    let dataToSave = {
      username: username,
    }

    setConfirmDialog({
      isOpen: false,
      title: "",
      subTitle: "",
    });

    UserService.Logout(dataToSave).then(() => {
      debugger;
      UserService.refreshList();
    }
    );

  }


  const handleDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    UserService.Delete(id).then(() => {
      navigate(UserService.listPath);
      UserService.GetList().then(
        (response) => {
          setUsers(response.data.data);
          setFetching(false);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
          UserService.refreshList();
        },
        (error) => {
          setFetching(false);
          console.log(error.response.data);
          setNotify({
            isOpen: true,
            message: data.message,
            type: "error",
          });
        }
      );
    });


    if (isFetching) {
      return <LoadingSpinner />;
    }


  }
  return (
    <MDBox m={-3}>

      <Link
        to={UserService.editPath}
        state={{
          hasFormInfo: true,
          id: row.original.id,
          userName: row.original.username,
          callSign: row.original.callSign,
          mode: row.original.mode,
          sipNumber:row.original.sipNumber,
        }}
      >
        <tooltip title="Edit">
          <IconButton>
            {" "}
            <ModeEditOutlineIcon color="info" fontSize="small" />
          </IconButton>
        </tooltip>
      </Link>

      <Link
        to={"/adminResetPassword"}
        state={{
          hasFormInfo: true,
          userName: row.original.username,
        }}
      >
        <tooltip title="Change Password">
          <IconButton>
            <LockResetIcon color="info" fontSize="medium" />
          </IconButton>{" "}
        </tooltip>
      </Link>

      <tooltip title="Delete">
        <IconButton
          onClick={() => {
            setConfirmDialog({
              isOpen: true,
              title: "Are you sure to delete",
              subTitle: row.original.username,
              onConfirm: () => {
                handleDelete(row.original.id);
              },
            });
          }}
        >

          <DeleteTwoToneIcon color="error" fontSize="medium" />
        </IconButton>
      </tooltip>
      <tooltip title="Logout">
        <IconButton
          m={-3}
          onClick={() => {
            setConfirmDialog({
              isOpen: true,
              title: "Are you sure to Logout",
              subTitle: row.original.username,
              onConfirm: () => {
                logout(row.original.username
                )
              },
            });
          }}
        >
          <LogoutSharpIcon color="info" fontSize="small" />
        </IconButton>
      </tooltip>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
};

export default UserDeleteEdit;
