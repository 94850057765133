import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "./ResetInputControls";



function ResetForm({ formData }) {
  const { formField, values, errors, touched } = formData;
  const {  oldName, newName } = formField;
  const {
    oldName: oldNameV,
    newName: newNameV,
  } = values;

  const submitActionName = "Update" 
  
  return (
    <MDBox>   
      <MDBox m={3}>
        <FormField
          name={oldName.name}
          variant="outlined"
          fullWidth
          id={oldName.name}
          label={oldName.label}
          type={oldName.type}
          placeholder={oldName.placeholder}
          error={errors.oldName && touched.oldName}
          success={!errors.oldName}
          value={oldNameV}
        />
      </MDBox>
      <MDBox m={3}>
        <FormField
          name={newName.name}
          variant="outlined"
          fullWidth
          id={newName.name}
          label={newName.label}
          type={newName.type}
          placeholder={newName.placeholder}
          error={errors.newName && touched.newName}
          success={!errors.newName}
          value={newNameV}
        />
      </MDBox>
      <MDBox p={2}>
        <MDBox>
          <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
            <MDBox />
            <MDButton type="submit" variant="contained" color="info" fullWidth >{submitActionName}</MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>

  );
}

export default ResetForm;