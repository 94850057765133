import { useState, useEffect } from "react";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "./SensorInputControls";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import DeviceService from "../device/DeviceService";
import LoadingSpinner from "components/Helper/LoadingSpinner";

function SensorForm({ formData }) {
  // for device list
  const [devices, setDevices] = useState([]);
  const [isFetching, setFetching] = useState(false);

  function getAllDevices() {
    DeviceService.GetList().then((response) => {
      setDevices(response.data.data)
      setFetching(false);
    }, (error) => {
      setFetching(false);
      console.log(error.response.data);
    });
  }

  useEffect(() => {
    getAllDevices();
  }, []);

  if (isFetching) {
    return <LoadingSpinner/>
  }

  const { formField, values, errors, touched, isEdit, handleChange  } = formData;
  const { type, url, name, ip, timePeriod, deviceId ,handlerStatus , sipUser } = formField;
  const {
    type: typeV,
    url: urlV,
    name: nameV,
    ip: ipV,
    sipUser:sipUserV,
    timePeriod:timePeriodV,
    deviceId: deviceIdV,
    handlerStatus : handlerStatusV,
  } = values;
  const submitActionName = isEdit ? "Update" : "Add";

  return (
    <MDBox>
      <MDBox m={3}>
        <MDBox>
          <InputLabel >Sensor Type</InputLabel>
          <Select
            m={3}
            value={typeV}
            onChange={handleChange}
            label="type"
            fullWidth
            variant="standard"
            name={type.name}
            id={type.name}
            type={type.type}
            placeholder={type.placeholder}
          >
              <MenuItem value={"IP Cam"}>IP Cam</MenuItem>
            <MenuItem value={"OnBoard Cam"}>OnBoad Cam</MenuItem>
            <MenuItem value={"USB GPS"}>USB GPS</MenuItem>
            <MenuItem value={"OnBoard GPS"}>OnBoard GPS</MenuItem>

          </Select>
        </MDBox>
      </MDBox>
      <MDBox m={3}>
        <FormField
          name={name.name}
          variant="outlined"
          fullWidth
          id={name.name}
          label={name.label}
          type={name.type}
          placeholder={name.placeholder}
          error={errors.name && touched.name}
          success={!errors.name}
          value={nameV}
        />
      </MDBox>
      <MDBox m={3}>
        <MDBox>
          <InputLabel >Handler Status</InputLabel>
          <Select
            m={3}
            value={handlerStatusV}
            onChange={handleChange}
            label="type"
            fullWidth
            variant="standard"
            name={handlerStatus.name}
            id={handlerStatus.name}
            type={handlerStatus.type}
            placeholder={handlerStatus.placeholder}
          >
              <MenuItem value={"off"}>Disable</MenuItem>
            <MenuItem value={"tx"}>Only Tx</MenuItem>
            <MenuItem value={"rx"}>With Rx</MenuItem>
          </Select>
        </MDBox>
      </MDBox>
      <MDBox m={3}>
        <FormField
          name={timePeriod.name}
          variant="outlined"
          fullWidth
          id={timePeriod.name}
          label={timePeriod.label}
          type={timePeriod.type}
          placeholder={timePeriod.placeholder}
          value={timePeriodV}
          error={errors.timePeriod && touched.timePeriod}
          success={!errors.timePeriod}
        />
      </MDBox>
      <MDBox m={3}>
        <FormField
          name={ip.name}
          variant="outlined"
          fullWidth
          id={ip.name}
          label={ip.label}
          type={ip.type}
          placeholder={ip.placeholder}
          value={ipV}
          error={errors.ip && touched.ip}
          success={!errors.ip}
        />
      </MDBox> 
      <MDBox m={3}>
        <FormField
          name={sipUser.name}
          variant="outlined"
          fullWidth
          id={sipUser.name}
          label={sipUser.label}
          type={sipUser.type}
          placeholder={sipUser.placeholder}
          value={sipUserV}
          error={errors.sipUser && touched.sipUser}
          success={!errors.sipUser}
        />
      </MDBox> 
      {typeV != "USB GPS" || "OnBoard GPS" &&
      <MDBox m={3}>
        <FormField
          name={url.name}
          variant="outlined"
          fullWidth
          id={url.name}
          label={url.label}
          type={url.type}
          placeholder={url.placeholder}
          value={urlV}
        />
      </MDBox>
}
   
      <MDBox m={3}>
        <InputLabel >Device</InputLabel>
        <select
          onChange={handleChange}
          name={deviceId.name}
          variant="contained"
          fullWidth
          style={{width:"100%"}}
          value={deviceIdV}
          id={deviceId.name}
          error={errors.deviceId && touched.deviceId}
          success={!errors.deviceId}
          placeholder={deviceId.placeholder}>
            <option value="" >---Select Device---</option>
          {devices.map((device) => {
            if(device.id == deviceIdV) {
              return(
                <option value={device.id} selected>{device.ip}</option>
              )
            } else {
              return(
                <option value={device.id}>{device.ip}</option>
              )
            }
        })}
        </select>
      </MDBox>
     
      <MDBox p={2}>
        <MDBox>
            <MDButton
              type="submit"
              variant="contained"
              color="info"
              fullWidth

            >
              {submitActionName}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
  );

}
export default SensorForm;