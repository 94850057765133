import formFields from "./ResetFormFields";

const {
    formField: {username, newPassword, confirmPassword}
} = formFields;


export default {
    [username.name]:"",
    [newPassword.name]: "",
    [confirmPassword.name]: "",

}