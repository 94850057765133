import * as React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import { IconButton } from "@material-ui/core";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Notification from "../notifications/Notification";
import ConfirmDialog from "../notifications/ConfirmDialog";
import DeviceService from "./DeviceService";
import LoadingSpinner from "components/Helper/LoadingSpinner";

const DeviceDeleteEdit = ({ data }) => {
  const { row } = data;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [devices, setDevices] = useState([]);
  const [isFetching, setFetching] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleDelete = async (id) => {
    DeviceService.Delete(id).then(() => {
      setNotify({
        isOpen: true,
        message: "Deleted Successfully",
        type: "success",
      });
  
      DeviceService.GetList().then(
        (response) => {
          setDevices(response.data.data);
          DeviceService.refreshList();
          setFetching(false);
        },
        (error) => {
          setFetching(false);
          console.log(error.response.data);
        }
      );
    }).catch((error) => {
      setNotify({
        isOpen: true,
        message: error.response.data.message + ". " + error.response.data.messageDetail,
        type: "error",
      });

    });
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  if (isFetching) {
    return <LoadingSpinner/>
  }

  return (
    <MDBox m={-3}>
      <tooltip title="Edit">
      <IconButton>
        <Link
          to={DeviceService.formPath}
          state={{
            hasFormInfo: true,
            deviceId: row.original.deviceId,
            ip: row.original.ip,
            id: row.original.id,
          }}
        >
        <ModeEditOutlineIcon color='info' fontSize='small' />
        </Link>
      </IconButton>
      </tooltip>
      <tooltip title="Delete">
      <IconButton m={-3}
        onClick={() => {
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure to delete this record?",
            subTitle: "You can't undo this operation",
            onConfirm: () => {
              handleDelete(row.original.id);
            },
          });
        }}
      >
        <DeleteTwoToneIcon color='error' fontSize='medium'/>
      </IconButton>
     </tooltip>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
};
export default DeviceDeleteEdit;
