import * as React from "react";
import { useState , useEffect} from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Header from "./Header";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "table/Tables/DataTable";
import UserService from "./UserService";
import dataTableData from "./UserDataTable";

function UserMaster() {
  const [isFetching, setFetching] = useState(false);
  const [users, setUsers] = useState([]);


  function fetchData() {
    setFetching(true);
    UserService.GetList().then(
      (response) => {
        setUsers(response.data.data);
        setFetching(false);
      },
      (error) => {
        setFetching(false);
        console.log(error.response.data);
      }
    );
  }
  useEffect(() => {
    UserService.refreshList = fetchData;
    fetchData();
    const intervalTrigger = setInterval(() => UserService.refreshList(), 5000);
    return () => {
      clearInterval(intervalTrigger);
    }
  }, []);
 

  return (
    <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <Header />
      <Card>      
        <DataTable
          table={{
            columns: dataTableData.columns,
            rows: users,
          }}
          canSearch
        />
      </Card>
      <Footer />
    </DashboardLayout>
  );
}
export default UserMaster;
