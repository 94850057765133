import { IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import { useState } from "react";
import PingServices from "./PingServices"
import AuthenticationService from "services/AuthenticationService";
import ForwardToInboxTwoToneIcon from '@mui/icons-material/ForwardToInboxTwoTone';
import Notification from "../notifications/Notification";
import tooltip from "assets/theme/components/tooltip";

function DevicePing({data}){
  const { row } = data;
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const onSubmit=(data) =>{
    let dataToSave ={
      id:0,
      createdBy:data.currentUserName,
      sendTo:data.callSign,
      message:"PING",
      messageType:"PING",
    }
    PingServices.Create(dataToSave).then(() => {
    setNotify({
        isOpen: true,
        message: "Message Sent Successfully",
        type: "success",
      });
   
}).catch(error => {
    setNotify({
      isOpen: true,
      message:error.response.data.message +" "+ error.response.data.messageDetail ,
      type: 'error'
    })});
  }

return(
    <MDBox m={-3}>
        <tooltip title="Ping">
        <IconButton m={-2}
    type="submit" variant="contained" onClick={() => onSubmit({
      currentUserName:AuthenticationService.getUserName(),
      callSign:row.original.callSign
    })}>
     <ForwardToInboxTwoToneIcon color="info" fontSize='small'/>
  </IconButton>
  </tooltip>
    <Notification notify={notify} setNotify={setNotify}/>
  </MDBox>
  
);
}
export default DevicePing;