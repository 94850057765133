import axios from "axios";
import AuthenticationService from "./AuthenticationService";

const SensorService = {
    GetList: function () {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.get(
            window.BASE_URL_API + "/sensor/List", {
                headers: {
                    Authorization:"Bearer " + token
                  }
            }

        );
    },
    Create: function (data) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.post(
            window.BASE_URL_API + "/sensor/register", data, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    Update: function (data) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.put(
            window.BASE_URL_API + "/sensor/" + data.id, data, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    },
    Delete: function (id) {
        let token = AuthenticationService.getAuthenticationToken();
        return axios.delete(
            window.BASE_URL_API + "/sensor/" + id, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
    }
}   

export default SensorService;