import ColumnIndex from "components/Helper/ColumnIndex";
import ExportImport from "./ExportImport";

const dataTableData = {
  columns: [
    { Header: "No", accessor: "No",  Cell: (value) => <ColumnIndex data={value} /> },
    { Header: "Name", accessor: "name" },
    { Header: "Date", accessor: "date" },
    { Header: "Action", accessor: "Action" , Cell: (value) => <ExportImport data={value}/> },
  ],
};

export default dataTableData;
