import { useState } from 'react';
import WebSocketService from './WebSocketService';
import MessageNotification from "layouts/pages/Module/notifications/MessageNotification";


function WebSocket({ subscribeUrl, topic }) {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
  const callback = function (message) {
    if (message.body) {
      const data = JSON.parse(message.body);
      setNotify({
        isOpen: true,
        message: data.message,
        type: data.messageType,
      });
    } else {
      setNotify({
        isOpen: true,
        message: 'got empty message',
        type: "warning",
      });
    }
  };

  WebSocketService.init(subscribeUrl, topic, callback);




  return (<>
    <MessageNotification notify={notify} setNotify={setNotify} />
  </>)
}

export default WebSocket;