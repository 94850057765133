import * as React from "react";
import { useState } from "react";
import MDBox from "components/MDBox";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import MessageService from "./MessageService";
import Notification from "../Module/notifications/Notification";
import ConfirmDialog from "../Module/notifications/ConfirmDialog";
import { IconButton } from "@material-ui/core";
import LoadingSpinner from "components/Helper/LoadingSpinner";

function MessageDelete({ data }) {
  const { row } = data;
  const [Message, setMessage] = useState([]);
  const [isFetching, setFetching] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    MessageService.Delete(id).then(() => {
      MessageService.GetList()
        .then((response) => {
          setMessage(response.data.data);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
          MessageService.refreshList();
          setFetching(false);
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
          });
        });
    });
  };

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <MDBox m={-3} >
       <tooltip title="Delete">
      <IconButton endIcon
        onClick={() => {
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure to delete this record?",
            subTitle: row.original.message,
            onConfirm: () => {
              handleDelete(row.original.id);
            },
          });
        }}
      >
        <DeleteTwoToneIcon color="error" fontSize="medium" />
      </IconButton>
      </tooltip>  
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default MessageDelete;
