import { useState } from "react";
import ReactSwitch from 'react-switch';
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import Typography from '@mui/material/Typography';
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import SensorService from "./SensorService";
import sensorAvatar from "assets/images/sensorAvatar.jpg";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";


// Images
import backgroundsensor from "assets/images/backgroundsensor.jpg";
import MDButton from "components/MDButton";

function Header({ children }) {
  const [checked, setChecked] = useState(true);
  
  const HandleChange = val => {
    setChecked(val)
    if(checked === "On") {
      const intervalTrigger = setInterval(() => SensorService.refreshList(), 5000);
      return () => {
        clearInterval(intervalTrigger);
          
          }
  }}
  return (
    <MDBox position="relative" mb={1}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.3),
              rgba(gradients.info.state, 0.3)
            )}, url(${backgroundsensor})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={sensorAvatar}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Sensor Master
              </MDTypography>
            </MDBox>
            <ReactSwitch
              checked={checked}
              onChange={HandleChange}
            />
             <Typography variant="caption" display="block" gutterBottom>
            Auto Refresh {checked ? 'On' : 'Off'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={1} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <MDButton variant="gradient" color="info">
                <Icon
                  fontSize="large"
                  color="inherit"
                  label="Refresh"
                  onClick={() => SensorService.refreshList()}
                >
                  <RefreshTwoToneIcon />
                </Icon>
              </MDButton>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
