import * as React from 'react';
import MDBox from 'components/MDBox';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PlayArrowRounded from '@material-ui/icons/PlayArrowRounded';
import { IconButton } from "@material-ui/core";
import VideoServices from './VideoServices';
import Notification from '../Module/notifications/Notification';
import ConfirmDialog from '../Module/notifications/ConfirmDialog';
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";


const VideoPlay = ({ data }) => {

    const { row } = data;
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });

    const navigate = useNavigate();


    const handleDelete = async (fileName) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
        
        VideoServices.DeleteFile(fileName).then(() => {
            navigate("/videos");
            VideoServices.getFilterData();
        });
    }


    return (
        <MDBox m={-3}>
            <tooltip title="play">
                <IconButton onClick={() => {
                    // navigate("/PlayVideo");
                    VideoServices.playVideo(row.original.name);
                }} >
                    <PlayArrowRounded color="disabled" />
                </IconButton>
            </tooltip>
            <tooltip title="Delete">
                <IconButton
                    onClick={() => {
                        setConfirmDialog({
                            isOpen: true,
                            title: "Are you sure to delete",
                            subTitle: row.original.name,
                            onConfirm: () => {
                                handleDelete(row.original.name);
                            },
                        });
                    }}
                >

                    <DeleteTwoToneIcon color="error" fontSize="medium" />
                </IconButton>
            </tooltip>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
            <Notification notify={notify} setNotify={setNotify} />
        </MDBox>
    );
}
export default VideoPlay;