import axios from "axios";
import AuthenticationService from "services/AuthenticationService"; 

const PingServices = {
    controllerName: "messaging",
Create: function (data) {
    let token = AuthenticationService.getAuthenticationToken();
    return axios.post(
        window.BASE_URL_APP + "/" + this.controllerName + "/request" + "/sendMessage", data, {
        headers: {
            Authorization: "Bearer " + token
        }
    }
    );
},
}
export default PingServices
