import formFields from "./ResetFormFields";

const {
    formField: { oldName, newName}
} = formFields;


export default {
    [oldName.name]: "",
    [newName.name]: "",

}