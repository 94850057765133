// @mui material components
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Notification from "../notifications/Notification";

//formfield
import definitions from "./UserFormFields";
import initialValues from "./UserInitialValues";
import validations from "./UserValidations";
import UserService from "./UserService";
import NewUser from "./UserForm";


function UserFormContainer(data) {
 
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { formField } = definitions;
  const defaultValues = { ...initialValues };

  if (location.state) {
    if (location.state.hasFormInfo) {
      defaultValues.id = location.state.id;
      defaultValues.userName = location.state.userName;
      defaultValues.callSign = location.state.callSign;
      defaultValues.mode = location.state.mode;
    } else {
      defaultValues.id = 0;
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper alternativeLabel>
                  <Step>
                    <StepLabel>Add User</StepLabel>
                  </Step>
                </Stepper>
                <Formik
                  validationSchema={validations}
                  initialValues={defaultValues}
                  onSubmit={(dataToSave) => {
                 
                    dataToSave.username = dataToSave.userName;
                    console.log(dataToSave);

                    if (dataToSave.id) {
                      UserService.Update(dataToSave).then(() => {
                        setNotify({
                          isOpen: true,
                          message: "updated Successfully",
                          type: "success",
                        });
                        navigate(UserService.listPath);
                      }).catch(error => {
                        setNotify({
                          isOpen: true,
                          message:error.response.data.message +" "+ error.response.data.messageDetail ,
                          type: 'error'
                        })});
                    } else {
                      UserService.Create(dataToSave).then(() => {
                        setNotify({
                          isOpen: true,
                          message: "Submitted Successfully",
                          type: "success",
                        });
                        navigate(UserService.listPath);
                      }).catch(error => {
                        setNotify({
                          isOpen: true,
                          message:error.response.data.message +" "+ error.response.data.messageDetail ,
                          type: 'error'
                        })});
                    }
                   
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    handleChange,
                  }) => (
                    <Form>
                      <NewUser
                        formData={{
                          values,
                          errors,
                          formField,
                          touched,
                          isSubmitting,
                          handleChange,
                          isEdit: defaultValues.id  ? true : false,
                        }}
                      />
                    </Form>
                  )}
                </Formik>
                <Notification notify={notify} setNotify={setNotify} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserFormContainer;
