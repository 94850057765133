// @mui material components
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik"
import { useState} from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Notification from "layouts/pages/Module/notifications/Notification";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//formfield
import { useNavigate, useLocation} from "react-router-dom";
import ConfigServices from "../configServices";
import ResetForm from "./ResetForm";
import formFields from "./ResetFormFields";
import ResetInitialValues from "./ResetInitialValues";
import validations from "./ResetValidations";

function ChangeFileName() {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
  const navigate = useNavigate();
  const { formField } = formFields;
  const defaultValues = { ...ResetInitialValues }
    
  const location = useLocation();

   if (location.state) {
     if (location.state.hasFormInfo) {
         defaultValues.oldName = location.state.oldName;
         defaultValues.newName = location.state.newName;
     } else {
         defaultValues.id = 0;
     }
 }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper alternativeLabel>
                  <Step>
                    <StepLabel>Change File Name</StepLabel>
                  </Step>
                </Stepper>
                <Formik
                  validationSchema={validations}
                  initialValues={defaultValues}
                  onSubmit={(data) => {
                    let dataToSave = {
                      oldName: data.oldName,
                      newName: data.newName,
                    }
                 
                    if (dataToSave) {
                      ConfigServices.Create(dataToSave).then(() => {
                        setNotify({
                          isOpen: true,
                          message: "Updated Successfuly",
                          type: 'success'
                        });
                        navigate("/Configuration");

                      }).catch(error => {
                        setNotify({
                          isOpen: true,
                          message: error.response.data.message + " " + error.response.data.messageDetail,
                          type: 'error'
                        })

                      });
                    }
                  }}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form>
                      <ResetForm formData={{
                        values,
                        errors,
                        formField,
                        touched,
                        isSubmitting,
                      }} />
                    </Form>
                  )}
                </Formik>

                <Notification
                  notify={notify}
                  setNotify={setNotify}
                />
              </MDBox>

            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ChangeFileName;