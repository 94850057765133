import { useState } from "react";
import ConfigServices from "./configServices";
import Notification from "../Module/notifications/Notification";
import { Link, useNavigate } from "react-router-dom";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import MDBox from "components/MDBox";
import { IconButton } from "@material-ui/core";
import MDTypography from "components/MDTypography";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ConfirmDialog from "../Module/notifications/ConfirmDialog";

function ExportImport({ data }) {
  const { row } = data;
  const navigate = useNavigate();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const Restoring = (data) => {
    let dataToSave = {
      backupName: data.name
    };
    ConfigServices.Restore(dataToSave)
      .then(() => {
        setNotify({
          isOpen: true,
          message: "Restore Done",
          type: "success",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
        });
      });
  };
  //Download
  const download = (fileName) => {
    let dataToSave = {
      name: fileName,
    };
    ConfigServices.Download(dataToSave)
      .then(() => {
        setNotify({
          isOpen: true,
          message: "File Successfully Download",
          type: "success",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
        });
      });
  };
  return (
    <>
      <MDBox m={-2}>
        <Link
          to="/ChangeFileName"
          state={{
            hasFormInfo: true,
            oldName: row.original.name,
            newName: row.original.name,
          }}
        >
          <IconButton fontSize="medium">
            <ChangeCircleIcon fontSize="medium" color="info" />
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;Change Name
            </MDTypography>
          </IconButton>
        </Link>
        <IconButton fontSize="medium" onClick={() => Restoring({
          name: row.original.name
        })}>
          <RestorePageIcon fontSize="medium" color="info" />
          <MDTypography variant="caption" color="secondary">
            &nbsp;&nbsp;Restore
          </MDTypography>
        </IconButton>
        <IconButton fontSize="medium">
          {" "}
          <DownloadForOfflineIcon fontSize="medium" color="info" />
          <a
            href={
              window.BASE_URL_APP + "/auth" + "/backup/" + row.original.name
            }
          >
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;Download
            </MDTypography>
          </a>
        </IconButton>
        <IconButton fontSize="medium" onClick={() => {
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure to delete",
            subTitle: row.original.name,
            onConfirm: () => {
              ConfigServices.DeleteFile(row.original.name);
              navigate("/Configuration");
            },
          });
        }}
        // onClick={() => ConfigServices.DeleteFle(row.original.name)}
        >
          <DeleteTwoToneIcon color="error" fontSize="medium" />
          <MDTypography variant="caption" color="secondary">
            &nbsp;&nbsp;Delete
          </MDTypography>
        </IconButton>

      </MDBox>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
export default ExportImport;
