
const LoadingSpinner=()=>{


return(
  <>
<div style={{ top: "50%", left: "50%" }}>
      <i
        style={{ fontSize: 36, color: "#1A73E8" }}
      />
    </div>
    </>
);
}    


export default LoadingSpinner;